import DictionaryService from '@/services/DictionaryService';

export default {
  data() {
    return {
      categories: [],
    };
  },
  async created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      let res = null;

      try {
        res = await DictionaryService.categories();
        this.categories = res.data;
      } catch (e) {
        this.categories = [];
        this.handleException(e);
      }
    },
  },
};
