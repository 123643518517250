<template>
  <div class="file__item">
    <div class="file__img">
      <img
        :src="media.filename.includes('.pdf') ? require('@/assets/images/pdf.png') : media.filename"
        alt=""
      >
    </div>
    <div class="file__text" style="position: relative">
      <span v-if="!isEditName">{{media.title}}</span>
      <input v-else v-model="media.title" type="text" class="form-control" @keyup.enter="update">
      <span v-if="isEditName"
        class="message_badge"
        :class="getClassBadgeLength(1)"
        style="right: 40px; background-color: #72cbed;"
        @click.prevent="update"
      >&#10004;</span>
      <span v-if="isEditName"
        class="message_badge"
        :class="getClassBadgeLength(1)"
        @click.prevent="isEditName = false"
      >&#10008;</span>
    </div>
    <div class="file__action">
      <ul class="file__action-list">
        <li class="file__action-item"><span class="icon-num">{{sortIndex}}</span></li>
        <li class="file__action-item">
          <a href="" @click.prevent="sort('up')">
            <span class="icon-arrow-up"></span>
          </a>
        </li>
        <li class="file__action-item">
          <a href="" @click.prevent="sort('down')">
            <span class="icon-arrow-down"></span>
          </a>
        </li>
        <li class="file__action-item">
          <a href="" @click.prevent="isEditName = true">
            <span class="icon-edit"></span>
          </a>
        </li>
        <li class="file__action-item">
          <a href="" @click.prevent="$emit('delete', media.id)">
            <span class="icon-delete"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import badge from '@/mixins/badge';

export default {
  name: 'FileMediaCard',
  components: {},
  mixins: [badge],
  props: {
    sortIndex: {
      type: Number,
    },
    media: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isEditName: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    update() {
      this.$emit('update', this.media.id, { title: this.media.title });
      this.isEditName = false;
    },
    delete() {},
    sort(direction) {
      this.$emit('sort', this.media.id, direction);
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute !important;
  top: 50%;
  right: 10px;

  cursor: pointer;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  transform: translateY(-50%);

  color: #fff;
  font-size: 12px;
  font-weight: 600;
}
</style>
