<template>
  <div class="editor-summary">
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <div class="editor-summary__wrapper">
      <div class="editor-summary__header">
        <h5 class="editor-summary__title">Add a Video</h5>
        <ValidationProvider v-slot="{ errors, failed }">
          <div class="form-row" :class="{ 'has-error': failed }">
            <div class="editor-summary__subtitle" style="margin-bottom: 25px;">
              Here you can insert a URL from a video platform (for example YouTube).
              The video will then be embedded.
            </div>
            <div style="position: relative">
              <input
                v-model="videoURL"
                class="form-control"
                type="text"
                name="url"
                pattern="^https?://.+$"
                placeholder="Insert URL">
              <span v-if="video && videoURL"
                class="message_badge"
                :class="getClassBadgeLength(1)"
                @click.prevent="onRemoveVideoUrl"
              >x
              </span>
            </div>
            <span :class="{ 'error': failed, 'help-message': failed }">{{ errors[0] }}</span>
            <div class="user-cabinet__area-top">
              <a
                class="btn btn-primary"
                @click.prevent="onSaveVideoUrl"
                :disabled="textButtonUpdateVideo !== 'Update video'"
              > {{textButtonUpdateVideo}}
              </a>
            </div>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue';

import badge from '@/mixins/badge';
import alert from '@/mixins/alert';

export default {
  name: 'EditorVideoBlock',
  components: { Alert },
  mixins: [badge, alert],
  props: {
    textButtonUpdateVideo: {
      type: String,
      default: 'Update video',
    },
    video: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoURL: this.video,
      textUpload: 'Upload a file',
      textButtonUpload: 'Upload a file',
    };
  },
  created() {},
  computed: {},
  methods: {
    onRemoveVideoUrl() {
      this.videoURL = '';
      this.$emit('onSave', { video: '' });
    },
    onSaveVideoUrl() {
      this.$emit('onSave', { video: this.videoURL });
    },
  },
  watch: {
    video() {
      this.videoUrl = this.video;
    },
  },
};
</script>
<style lang="scss" scoped>
.editor-summary {
  margin-bottom: 50px;

  // &__wrapper {
  //   text-align: center;
  // }

  &__editor {
    margin-top: 40px;
  }
}

.user-cabinet__area-top {
  margin-bottom: 20px;
  margin-top: 30px;
}

.user-cabinet__area-top a[disabled] {
  background-color: #eee;
  cursor: default;
}

.user-cabinet__area-top a[disabled]:hover {
  color: #000;
}

.error {
  text-align: left;
  /* position: relative; */
  /* left: 0; */
  display: inline-block;
  width: 100%;
  padding-left: 20px;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute !important;
  top: 50%;
  right: 10px;

  cursor: pointer;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  transform: translateY(-50%);

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
