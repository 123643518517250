import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ETHERPAD_URL + process.env.VUE_APP_ETHERPAD_PATH,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  params: {
    apikey: process.env.VUE_APP_ETHERPAD_API_KEY,
  },
});

export default axiosInstance;
