import axios from '@/libs/axios/axiosEditor';

export default {
  /**
   * Send request for create Author If Not Exists
   * @returns {Object}
   */
  async createAuthorIfNotExistsFor(data) {
    const res = await axios.get('createAuthorIfNotExistsFor', { params: data });
    return res;
  },

  /**
  * Send request for create Group If Not Exists
  * @returns {Object}
  */
  async createGroupIfNotExistsFor(data) {
    const res = await axios.get('createGroupIfNotExistsFor', { params: data });
    return res;
  },

  /**
  * Send request for create Group Pad
  * @returns {Object}
  */
  async createGroupPad(data) {
    const res = await axios.get('createGroupPad', { params: data });
    return res;
  },

  /**
  * Send request for create Group Pad
  * @returns {Object}
  */
  async deletePad(data) {
    const res = await axios.get('deletePad', { params: data });
    return res;
  },

  /**
  * Send request for create Group Pad
  * @returns {Object}
  */
  async createSession(data) {
    const res = await axios.get('createSession', { params: data });
    return res;
  },

  /**
  * Send request for Set Text Pad
  * @returns {Object}
  */
  async setText(params) {
    const res = await axios.get('setText', { params });
    return res;
  },

  /**
  * Send request for Get Text Pad
  * @returns {Object}
  */
  async getText(data) {
    const res = await axios.get('getText', { params: data });
    return res;
  },

  /**
  * Send request for Get HTML Pad
  * @returns {Object}
  */
  async setHTML(params) {
    const res = await axios.get('setHTML', { params });
    return res;
  },

  /**
  * Send request for Get HTML Pad
  * @returns {Object}
  */
  async getHTML(data) {
    const res = await axios.get('getHTML', { params: data });
    return res;
  },
};
