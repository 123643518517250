<template>
    <div class="editor__sidebar">
      <div class="editor__logo">
        <router-link to="/">
          <img src="@/assets/images/logo.svg" alt="WeThink">
        </router-link>
        <router-link
          :to="{ name: 'strategyProjectPageView', params: { id: $route.params.id } }"
          class="btn-back"
        >
          Go Back
        </router-link>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <button
              v-b-toggle.collapseCategory
              class="btn-accordion"
              type="button"
            >
              <i class="icon-category"></i> <span>Category</span>
            </button>
          </div>
          <b-collapse
            id="collapseCategory"
          >
            <ListCategories
              :curCategory="category"
              :authUserType="authUserType"
            />
          </b-collapse>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
              <button
                v-b-toggle.collapseStatus
                class="btn-accordion collapsed"
                type="button"
              >
                <i class="icon-status"></i> <span>Status</span>
              </button>
          </div>
          <b-collapse id="collapseStatus">
            <div class="card-body">
              <ul class="radio-list">
                <li v-for="type in typesOfProject" :key="type.id + type.name">
                  <label>
                    <input
                      v-model="selectedStatus"
                      name="status"
                      class="radio-box"
                      type="radio"
                      :value="type.id"
                      :disabled="authUserType !== 'owner'"
                      @change="onChangeStatus"
                    >
                    <span class="radio-style"></span>{{type.name}}
                  </label>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
              <button
                v-b-toggle.collapseMembers
                class="btn-accordion collapsed"
                type="button"
              >
                <i class="icon-members"></i>  <span>
                  <span v-if="unreadMembersCount &&
                    (authUserType === 'owner' || authUserType === 'admin')"
                    class="message_badge"
                    :class="getClassBadgeLength(unreadMembersCount)"
                  >{{unreadMembersCount}}
                  </span>
                  Project Members</span>
              </button>
          </div>
          <b-collapse id="collapseMembers" @show="getUsers">
            <div class="card-body">
              <ListOfUsers
                v-if="users.owner.length"
                :users="users.owner"
                :authUserType="authUserType"
              />
              <ListOfUsers
                :users="users.admin"
                :authUserType="authUserType"
                type="admin"
              />
              <ListOfUsers
                :users="users.request"
                :authUserType="authUserType"
                type="request"
              />
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
</template>
<script>
import { BCollapse, VBToggle } from 'bootstrap-vue';

import ListCategories from '@/views/pages/StrategyProjectEditor/Components/LeftSideMenu/ListCategories.vue';
import ListOfUsers from '@/views/pages/StrategyProjectEditor/Components/LeftSideMenu/ListOfUsers.vue';

import badge from '@/mixins/badge';

export default {
  name: 'LeftSideMenu',
  components: { BCollapse, ListCategories, ListOfUsers },
  directives: { 'b-toggle': VBToggle },
  mixins: [badge],
  props: {
    category: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    users: {
      type: Object,
    },
    authUserType: {
      type: String,
    },
    goBackPathName: {
      type: String,
    },
  },
  data() {
    return {
      selectedStatus: this.status === 'Open' ? 1 : 2,
      typesOfProject: [
        {
          id: 1,
          name: 'Open',
        },
        {
          id: 2,
          name: 'Private',
        },
      ],
    };
  },
  computed: {
    unreadMembersCount() {
      return this.$store.getters.getUnreadMembersCount;
    },
  },
  created() {
    this.$eventBus.$on('updated_members', this.setUnreadMembersCount);
  },
  methods: {
    onChangeStatus() {
      this.$eventBus.$emit('updateProjectProperty', { type: this.selectedStatus });
    },
    setUnreadMembersCount() {
      this.$store.commit(
        'SET_UNREAD_MEMBERS_COUNT',
        this.unreadMembersCount + 1,
      );
    },
    getUsers() {
      if (!this.users.owner?.length) {
        this.$eventBus.$emit('loadUsers');
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('updated_members', this.setUnreadMembersCount);
  },
};
</script>

<style lang="scss" scoped>
.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute !important;
  top: -10px;
  right: -30px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
