<template>
  <div v-if="project.id" id="editor_wrapper" class="editor__wrapper">
    <LeftSideMenu
      :category="project.category_id"
      :status="project.type"
      :users="users"
      :authUserType="authUserType"
      :goBackPathName="goBackPathName"
    />
    <EditorSection v-if="project.id"
      :summaryText="project.summary"
      :summaryFile="project.summary_file"
      :video="project.video"
      :authUserType="authUserType"
      :projectIDNumber="project.id"
      :projectPad="project.pad"
      :description="project.name"
      :users="allUser"
      :countUsers="project.cnt_user"
      :isGroup="project.group_id || 0"
    />
    <Modal
      @close="hideModal('userActionModal')"
      id="userActionModal"
      title="Are you sure?"
      :buttonTextOk="modal.btnCaption"
      :buttonVariantOk="modal.variant"
      :showButtonCancel="true"
      @cancel="hideModal('userActionModal')"
      @ok="onActionUser"
    />
</div>
</template>

<script>
import LeftSideMenu from '@/views/pages/StrategyProjectEditor/Components/LeftSideMenu/Index.vue';
import EditorSection from '@/views/pages/StrategyProjectEditor/Components/EditorSection/Index.vue';

import Modal from '@/components/Modal.vue';

import ProjectService from '@/services/ProjectService';

import exception from '@/mixins/exception';
import me from '@/mixins/me';
import modal from '@/mixins/modal';
import websocket from '@/mixins/websocket';

export default {
  name: 'StrategyProjectEditor',
  components: { LeftSideMenu, EditorSection, Modal },
  mixins: [exception, me, modal, websocket],
  data() {
    return {
      goBackPathName: '',
      project: {},
      users: {
        owner: [],
        admin: [],
        request: [],
        member: [],
      },
      modal: {
        id: 'userActionModal',
        variant: 'danger',
        btnCaption: '',
        userID: 0,
        type: '',
        action: '',
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    /* eslint-disable linebreak-style */
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.goBackPathName = from.name || 'mainAuthorization';
    });
  },
  async created() {
    await this.getProject();
    await this.loadUsers();

    if (this.checkProjectLocked()) {
      this.project = {};
      this.$router.push({ name: 'strategyProjectPageView', props: { id: this.$route.params.id } });
    }

    this.subscribeToChannel('project', this.setUnreadMembers, this.project.id);

    this.$eventBus.$on('loadUsers', this.loadUsers);
    this.$eventBus.$on('actionUser', this.onShowModal);
    this.$eventBus.$on('addUser', this.addUser);
    this.$eventBus.$on('updateTitle', this.setTitle);
    this.$eventBus.$on('onUpdateSummary', this.updateSummary);
    // this.$eventBus.$on('scrollToTop', this.scrollToTop);
  },
  computed: {
    authUserType() {
      return this.project.role;
    },
    allUser() {
      return [
        ...this.users.owner,
        ...this.users.admin,
        ...this.users.member,
        ...this.users.request,
      ];
    },
  },
  methods: {
    async loadUsers() {
      if (this.project.id) {
        this.users.owner = await this.getUsersOfProject('owner');
        this.users.admin = await this.getUsersOfProject('admin');
        this.users.member = await this.getUsersOfProject('member');
        this.users.request = await this.getUsersOfProject('request');
      }
    },
    async getProject() {
      let res = null;

      try {
        res = await ProjectService.project(this.$route.params.id);

        this.project = res.data;
      } catch (e) {
        this.project = {};
        this.handleException(e);
      }
    },
    async getUsersOfProject(filter) {
      let res = null;

      try {
        res = await ProjectService.getUsersProject(this.$route.params.id, { filter, 'per-page': 0 });
        return res.data;
      } catch (e) {
        this.project = {};
        if (e.response && e.response.status === 404) {
          this.$router.push({ name: 'strategyProjectPageView', props: { id: this.$route.params.id } });
        }

        return [];
      }
    },
    onShowModal(user, type, action) {
      if (!type || !action) return;

      this.modal.user = user;
      this.modal.type = type;
      this.modal.action = action;

      if (action === 'member') {
        this.modal.btnCaption = 'Accept';
      } else if (action === 'accept' || action === 'moderator') {
        this.modal.btnCaption = 'Accept';
      } else if (action === 'deny') {
        this.modal.btnCaption = 'Deny';
      } else if (action === 'revoke') {
        this.modal.btnCaption = 'Kick Out';
      }

      this.showModal(this.modal.id);
    },
    async onActionUser() {
      let res = null;

      try {
        res = await ProjectService.actionUser(this.project.id, {
          action: this.modal.action,
          user_id: this.modal.user.id,
        });

        this.$eventBus.$emit('actionUser', res.data.message);
        this.users[this.modal.type] = this
          .users[this.modal.type]
          .filter((user) => user.id !== this.modal.user.id);

        if (this.modal.action === 'moderator') {
          this.addUser('admin');
        } else if (this.modal.action === 'member') {
          this.addUser('member');
        } else if (this.modal.action === 'revoke') {
          this.project.cnt_user -= 1;
        }

        this.hideModal(this.modal.id);
      } catch (e) {
        this.$eventBus.$emit('actionUser', e.response.data.message, 'danger');
        this.hideModal(this.modal.id);
      }
    },
    addUser(type) {
      if (type === 'admin') this.modal.user.type = 'admin';
      this.users[type].push(this.modal.user);
    },
    scrollToTop() {
      document.getElementById('editor_wrapper').scrollIntoView({ behavior: 'smooth' });
    },
    checkProjectLocked() {
      if (this.project.group_id
        && (this.project.role_group === 'guest' || this.project.role_group === 'request')
      ) {
        return true;
      }

      if (this.project.role === 'guest' || this.project.role === 'request' || this.project.role === 'hidden') {
        return true;
      }

      return false;
    },
    async setTitle(name) {
      try {
        const slug = `/projects/${name.replaceAll(' ', '-')}/edit`;

        await ProjectService.update(this.$route.params.id, { name });
        this.project.name = name;

        if (this.$route.path === slug) return;
        this.$router.push(slug);
      } catch (e) {
        this.handleException(e);
      }
    },
    updateSummary() {
      this.getProject();
    },
    setUnreadMembers(event) {
      const updateMembers = JSON.parse(event.data).member_update;
      const unreadMembers = JSON.parse(event.data).message;
      if (updateMembers) {
        unreadMembers.updateMembers = 1;
        this.users.request.unshift(updateMembers);
        this.$eventBus.$emit('updated_members', updateMembers);
      }

      this.$store.commit(
        'SET_UNREAD_MEMBERS_COUNT',
        unreadMembers.members.length,
      );
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('loadUsers', this.loadUsers);
    this.$eventBus.$off('actionUser', this.onShowModal);
    this.$eventBus.$off('addUser', this.addUser);
    this.$eventBus.$off('updateTitle', this.setTitle);
    this.$eventBus.$off('onUpdateSummary', this.updateSummary);
  },
};
</script>
