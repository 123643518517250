import axios from '@/libs/axios';

export default {
  /**
   * Send request for get medias
   * @returns {Object}
   */
  async get(id, query = {}) {
    const res = await axios.get(`media/${id}`, { params: query });
    return res;
  },

  /**
   * Send request for update media
   * @returns {Object}
   */
  async update(id, data) {
    const res = await axios.put(`media/${id}`, data);
    return res;
  },

  /**
   * Send request for sort medias
   * @returns {Object}
   */
  async sort(id, direction, data) {
    const res = await axios.put(`media/${id}/sort/${direction}`, data);
    return res;
  },

  /**
   * Send request for delete media
   * @returns {Object}
   */
  async delete(id) {
    const res = await axios.delete(`media/${id}`);
    return res;
  },
};
