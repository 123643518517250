var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor__container"},[_c('div',{staticClass:"editor__header"},[_c('FiveAvatars',{attrs:{"users":_vm.users,"countUsers":_vm.countUsers - 1,"role":_vm.authUserType}}),(_vm.isGroup)?_c('div',{staticClass:"title-gradient"},[_c('span',[_vm._v("Group Strategy Project")])]):_vm._e(),_c('div',{staticClass:"editor__actions"},[(_vm.authUserType === 'owner'
          || _vm.authUserType === 'creator')?_c('button',{staticClass:"btn btn-primary btn_delete",on:{"click":function($event){return _vm.showModal(_vm.modalDeleteProject)}}},[_vm._v("Delete ")]):_vm._e(),(_vm.authUserType === 'owner'
          || _vm.authUserType === 'creator'
          || _vm.authUserType === 'admin'
          || _vm.authUserType === 'moderator')?_c('button',{staticClass:"btn btn-primary",staticStyle:{"background-color":"#72CBED","color":"#fff"},on:{"click":_vm.onUpdate}},[_vm._v("Publish ")]):_vm._e()]),(_vm.userPopap)?_c('UserMenuPopap',{attrs:{"user":_vm.userPopap,"authUserType":_vm.authUserType}}):_vm._e()],1),(_vm.text)?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('h1',{staticClass:"editor__title"},[(_vm.authUserType === 'owner' || _vm.authUserType === 'admin')?_c('span',{on:{"click":_vm.toggleEditTitle}},[_c('img',{staticStyle:{"width":"14px","margin-right":"5px","cursor":"pointer"},attrs:{"src":require("@/assets/images/icon_edit.svg")}})]):_vm._e(),(!_vm.isEditTitle)?_c('span',[_vm._v(_vm._s(_vm.description))]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newTitle),expression:"newTitle"}],ref:"editTitle",staticStyle:{"border":"none","borderBottom":"1px solid grey"},attrs:{"type":"text"},domProps:{"value":(_vm.newTitle)},on:{"blur":function($event){_vm.isEditTitle = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onUpdateTitle($event)},"input":function($event){if($event.target.composing){ return; }_vm.newTitle=$event.target.value}}})]),(_vm.authUserType === 'owner'
      || _vm.authUserType === 'creator'
      || _vm.authUserType === 'admin'
      || _vm.authUserType === 'moderator')?_c('EditorSummaryBlock',{attrs:{"text":_vm.summaryText,"file":_vm.summaryFile,"video":_vm.video,"textButtonSave":_vm.textButtonSave,"textButtonUpdateVideo":_vm.textButtonUpdateVideo},on:{"onSave":_vm.saveSummary,"onShowSizeAlert":_vm.showSizeAlert}}):_vm._e(),(_vm.authUserType === 'owner'
      || _vm.authUserType === 'creator'
      || _vm.authUserType === 'admin'
      || _vm.authUserType === 'moderator')?_c('EditorVideoBlock',{attrs:{"video":_vm.video,"textButtonUpdateVideo":_vm.textButtonUpdateVideo},on:{"onSave":_vm.saveSummary,"onShowSizeAlert":_vm.showSizeAlert}}):_vm._e(),(_vm.authUserType === 'owner'
      || _vm.authUserType === 'creator'
      || _vm.authUserType === 'admin'
      || _vm.authUserType === 'moderator')?_c('EditorMediaBlock',{attrs:{"projectID":_vm.projectIDNumber},on:{"onShowSizeAlert":_vm.showSizeAlert}}):_vm._e(),_c('div',{staticClass:"editor__area"},[_c('iframe',{attrs:{"id":"iframe_editor","src":_vm.urlEditor,"width":"100%","height":"700"}})]),_c('Modal',{attrs:{"id":_vm.modalDeleteProject,"title":"Are you sure?","buttonTextOk":"Delete","buttonVariantOk":"danger","showButtonCancel":true},on:{"close":function($event){return _vm.hideModal(_vm.modalDeleteProject)},"cancel":function($event){return _vm.hideModal(_vm.modalDeleteProject)},"ok":_vm.deleteProject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }