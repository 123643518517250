<template>
  <div class="editor-summary">
    <div class="editor-summary__wrapper">
      <div class="editor-summary__header">
        <h5 class="editor-summary__title">Add a summary</h5>
      </div>
      <div class="editor-summary__subtitle">
        Here you can summarize what your project is going to be about.
        Write it down or add a PDF or upload a small video.
      </div>
      <template>
        <div class="file-drop">
          <div class="file-drop__body">
            <input
              @change="onChangeFile"
              type="file"
              accept="application/pdf, video/*"
            >
            <img class="file-drop__icon" src="images/upload.png" alt="">
            <div v-if="!summary.file" class="file-drop__info">
              Formats: video, pdf<br>
              Max: 5Mb
            </div>
            <div v-else class="file-drop__info">
              Uploaded file:<br>
              {{fileName}}
            </div>
          </div>
        </div>
        <span
          v-if="summary.file"
          class="remove_file"
          @click="onRemoveFile"
        >
          Remove file
        </span>
        <a
          v-if="file && summary.file"
          class="uploaded_file"
          download
          target="_blank"
          :href="file"
        >
          Uploaded file. Click to open.
        </a>
      </template>
      <div class="editor-summary__editor">
        <textarea-autosize
          v-model="summary.text"
          class="growTextarea"
          rows="10"
          cols="30"
        ></textarea-autosize>
      </div>
      <div class="user-cabinet__area-top">
        <a
          class="btn btn-primary"
          @click.prevent="onSave"
          :disabled="textButtonSave !== 'Save'"
        >
          {{textButtonSave === 'Save' ? 'Update text' : textButtonSave}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import badge from '@/mixins/badge';

export default {
  name: 'EditorSummaryBlock',
  components: {},
  mixins: [badge],
  props: {
    text: {
      type: String,
      default: '',
    },
    file: {
      type: String,
      default: '',
    },
    textButtonSave: {
      type: String,
      default: 'Save',
    },
    textButtonUpdateVideo: {
      type: String,
      default: 'Update video',
    },
    video: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      summary: {
        text: this.text,
        file: this.file,
      },
      fileName: this.file ? this.file.split('/')[this.file.split('/').length - 1].split('?')[0] : '',
      textSave: 'Save',
      videoURL: this.video,
    };
  },
  created() {},
  computed: {},
  methods: {
    onChangeFile(event) {
      const file = event.target.files[0];

      if (file.type !== 'application/pdf' && !file.type.includes('video/')) {
        this.onRemoveFile();
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        this.fileName = event.target.files[0].name;

        if (event.target.files[0].size >= (1048576 * 5)) {
          this.$emit('onShowSizeAlert');
          return;
        }

        this.summary.file = reader.result;
        this.$emit('onSave', { summary_filename: this.fileName, summary_file: this.summary.file, summary: this.summary.text });
      };
    },
    onRemoveFile() {
      this.summary.file = false;
      document.querySelector('[type="file"]').value = '';
      this.$emit('onSave', { summary_filename: '', summary_file: '', summary: this.summary.text });
    },
    onRemoveVideoUrl() {
      this.videoURL = '';
      this.$emit('onSave', { video: '' });
    },
    onSaveVideoUrl() {
      this.$emit('onSave', { video: this.videoURL });
    },
    onSave() {
      this.$emit('onSave', {
        summary_filename: this.summary_filename,
        summary_file: this.summary.file,
        summary: this.summary.text,
      });
    },
  },
  watch: {
    video() {
      this.videoUrl = this.video;
    },
  },
};
</script>
<style lang="scss" scoped>
.editor-summary {
  margin-bottom: 50px;

  &__wrapper {
    text-align: center;
  }

  &__editor {
    margin-top: 40px;
  }
}

.file-drop {
  margin-bottom: 0;
}

.remove_file {
  display: block;
  color: red;
  font-size: 12px;

  cursor: pointer;
}

.uploaded_file {
  color: blue;
  font-size: 12px;

  cursor: pointer;
}

.user-cabinet__area-top {
  margin-bottom: 20px;
  margin-top: 30px;
}

.user-cabinet__area-top a[disabled] {
  background-color: #eee;
  cursor: default;
}

.user-cabinet__area-top a[disabled]:hover {
  color: #000;
}

.error {
  text-align: left;
  /* position: relative; */
  /* left: 0; */
  display: inline-block;
  width: 100%;
  padding-left: 20px;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute !important;
  top: 50%;
  right: 10px;

  cursor: pointer;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  transform: translateY(-50%);

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
