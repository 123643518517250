<template>
  <div class="editor__container">
    <div class="editor__header">
      <FiveAvatars
        :users="users"
        :countUsers="countUsers - 1"
        :role="authUserType"
      />
      <div v-if="isGroup" class="title-gradient"><span>Group Strategy Project</span></div>
      <div class="editor__actions">
        <button class="btn btn-primary btn_delete"
          v-if="authUserType === 'owner'
            || authUserType === 'creator'"
          @click="showModal(modalDeleteProject)">Delete
        </button>
        <button class="btn btn-primary" style="background-color: #72CBED; color: #fff;"
          v-if="authUserType === 'owner'
            || authUserType === 'creator'
            || authUserType === 'admin'
            || authUserType === 'moderator'"
          @click="onUpdate">Publish
        </button>
      </div>
      <UserMenuPopap
        v-if="userPopap"
        :user="userPopap"
        :authUserType="authUserType"
      />
    </div>
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <h1 class="editor__title">
      <span v-if="authUserType === 'owner' || authUserType === 'admin'" @click="toggleEditTitle">
        <img
          src="@/assets/images/icon_edit.svg"
          style="width: 14px; margin-right: 5px; cursor: pointer;"
        >
      </span>
      <span v-if="!isEditTitle">{{description}}</span>
      <input v-else
        v-model="newTitle"
        type="text"
        @blur="isEditTitle = false"
        @keyup.enter="onUpdateTitle"
        ref="editTitle"
        style="border: none; borderBottom: 1px solid grey;"
      >
    </h1>
    <EditorSummaryBlock
      v-if="authUserType === 'owner'
        || authUserType === 'creator'
        || authUserType === 'admin'
        || authUserType === 'moderator'"
      :text="summaryText"
      :file="summaryFile"
      :video="video"
      :textButtonSave="textButtonSave"
      :textButtonUpdateVideo="textButtonUpdateVideo"
      @onSave="saveSummary"
      @onShowSizeAlert="showSizeAlert"
    />
    <EditorVideoBlock
      v-if="authUserType === 'owner'
        || authUserType === 'creator'
        || authUserType === 'admin'
        || authUserType === 'moderator'"
      :video="video"
      :textButtonUpdateVideo="textButtonUpdateVideo"
      @onSave="saveSummary"
      @onShowSizeAlert="showSizeAlert"
    />
    <EditorMediaBlock
      v-if="authUserType === 'owner'
        || authUserType === 'creator'
        || authUserType === 'admin'
        || authUserType === 'moderator'"
      :projectID="projectIDNumber"
      @onShowSizeAlert="showSizeAlert"
    />
    <div class="editor__area">
      <iframe
      id="iframe_editor"
        :src="urlEditor"
        width="100%"
        height="700"
      ></iframe>
    </div>
    <Modal
      @close="hideModal(modalDeleteProject)"
      :id="modalDeleteProject"
      title="Are you sure?"
      buttonTextOk="Delete"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalDeleteProject)"
      @ok="deleteProject"
    />
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue';

import EditorService from '@/services/EditorServices';
import ProjectService from '@/services/ProjectService';
import FiveAvatars from '@/components/FiveAvatars.vue';
import UserMenuPopap from '@/components/UserMenuPopap.vue';
import EditorSummaryBlock from '@/views/pages/StrategyProjectEditor/Components/EditorSection/EditorSummaryBlock.vue';
import EditorVideoBlock from '@/views/pages/StrategyProjectEditor/Components/EditorSection/EditorVideoBlock.vue';
import EditorMediaBlock from '@/views/pages/StrategyProjectEditor/Components/EditorSection/EditorMediaBlock.vue';
import Modal from '@/components/Modal.vue';

import me from '@/mixins/me';
import modal from '@/mixins/modal';
import alert from '@/mixins/alert';
import random from '@/mixins/random';
import exception from '@/mixins/exception';

export default {
  name: 'SectionEditor',
  components: {
    Modal,
    Alert,
    FiveAvatars,
    UserMenuPopap,
    EditorSummaryBlock,
    EditorVideoBlock,
    EditorMediaBlock,
  },
  mixins: [me, exception, random, alert, modal],
  props: {
    summaryText: {
      type: String,
      default: '',
    },
    summaryFile: {
      type: String,
      default: '',
    },
    video: {
      type: String,
      default: '',
    },
    projectIDNumber: {
      type: Number,
    },
    projectPad: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    authUserType: {
      type: String,
      default: 'member',
    },
    countUsers: {
      type: Number,
      default: 0,
    },
    isGroup: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newTitle: this.description,
      isEditTitle: false,
      isNewProject: true,
      projectID: this.$route.params.id,
      pad: {
        authorID: this.projectPad.authorID,
        groupID: this.projectPad.groupID,
        padID: this.projectPad.padID,
      },
      urlEditor: undefined,
      sessionID: undefined,
      userPopap: null,
      textButtonSave: 'Save',
      textButtonUpdateVideo: 'Update video',
      modalDeleteProject: 'deleteModal',
    };
  },
  async created() {
    if (this.$route.params.alertText) {
      this.alert.text = this.$route.params.alertText;
      this.alert.variant = this.$route.params.alertVariant || 'success';
      this.showAndHideAlert();
    }

    this.$eventBus.$on('updateProjectProperty', this.updateProjectData);
    this.$eventBus.$on('actionUser', this.showModalAction);
    this.$eventBus.$on('avatarClick', this.showUserMenuPopap);
    this.$eventBus.$on('hideMenuPopap', this.showUserMenuPopap);
    this.$eventBus.$on('unfriendUser', this.unfriendUser);

    await this.createAuthorIfNotExistsFor();

    if (this.pad.authorID && this.pad.groupID && this.pad.padID) {
      this.isNewProject = false;
      await this.createSession();
    } else {
      await this.createGroupIfNotExistsFor();
      await this.createGroupPad();
      await this.createSession();
      await this.storePad();
    }

    this.urlEditor = `${this.$etherpadServerUrl}/p/${this.pad.padID}?showChat=true&showLineNumbers=false&userName=${this.me.name}`;
  },
  computed: {},
  methods: {
    async createAuthorIfNotExistsFor() {
      let res = null;

      try {
        res = await EditorService.createAuthorIfNotExistsFor({
          name: this.me.name,
          authorMapper: this.me.id,
        });
        if (res.data.code) {
          this.showAndHideAlertSuccess(res.data.message, 'danger');
          return;
        }
        this.pad.authorID = res.data.data.authorID;
      } catch (e) {
        this.handleException(e);
      }
    },
    async createGroupIfNotExistsFor() {
      let res = null;

      try {
        res = await EditorService.createGroupIfNotExistsFor({
          groupMapper: this.projectIDNumber,
        });
        if (res.data.code) {
          this.showAndHideAlertSuccess(res.data.message, 'danger');
          return;
        }
        this.pad.groupID = res.data.data.groupID;
      } catch (e) {
        this.handleException(e);
      }
    },
    async createGroupPad(randomValue = '') {
      let res = null;

      try {
        res = await EditorService.createGroupPad({
          groupID: this.pad.groupID,
          padName: `document${randomValue}_${this.projectIDNumber}`,
          text: '',
        });
        if (res.data.code === 1) {
          await this.createGroupPad(this.getRandomKey(999, 999999));
          return;
        }
        if (res.data.code) {
          this.showAndHideAlertSuccess(res.data.message, 'danger');
          return;
        }
        this.pad.padID = res.data.data.padID;
      } catch (e) {
        this.handleException(e);
      }
    },
    async createSession() {
      let res = null;

      try {
        res = await EditorService.createSession({
          groupID: this.pad.groupID,
          authorID: this.pad.authorID,
          validUntil: '1812201246',
        });

        if (res.data.code) {
          this.showAndHideAlertSuccess(res.data.message, 'danger');
          return;
        }
        this.sessionID = res.data.data.sessionID;
        document.cookie = 'sessionID=; Max-Age=0; path=/;';
        document.cookie = `sessionID=${res.data.data.sessionID};path=/`;
      } catch (e) {
        this.handleException(e);
      }
    },
    async onUpdate() {
      let text = await this.getEtherPadHTML();

      if (typeof text === 'object') {
        this.showAndHideAlertSuccess(text.response.message, 'danger');
        return;
      }

      text = this.convertText(text);
      this.updateProjectData({ text });
    },
    async onRequestPublish() {
      let res = null;

      try {
        res = await ProjectService.requestPublish(this.$route.params.id);
        this.showAndHideAlertSuccess(res.data.message);
      } catch (e) {
        this.handleException(e);
      }
    },
    async storePad() {
      try {
        await ProjectService.createPad(this.$route.params.id, this.pad);
      } catch (e) {
        this.handleException(e);
      }
    },
    async getEtherPadHTML() {
      let res = null;

      try {
        res = await EditorService.getHTML({
          padID: this.pad.padID,
        });

        return res.data.data.html;
      } catch (e) {
        this.handleException(e);
        return e;
      }
    },
    async updateProjectData(data) {
      let res = null;

      try {
        res = await ProjectService.update(this.$route.params.id, data);
        this.showAndHideAlertSuccess(res.data.message);

        if (typeof data.summary === 'string' || typeof data.summary_file === 'string') {
          this.textButtonSave = 'Save';
        } else if (data.video || data.video === '') {
          this.textButtonUpdateVideo = 'Update video';
        }

        return true;
      } catch (e) {
        this.handleException(e);
        return false;
      }
    },
    convertText(text) {
      return text.split('<body>')[1].split('</body>')[0];
    },
    showModalAction(text, type = 'success') {
      this.showAndHideAlertSuccess(text, type);
    },
    showUserMenuPopap(user) {
      if (this.userPopap && this.userPopap.id === user.id) this.userPopap = null;
      else this.userPopap = user;
    },
    async unfriendUser() {
      this.userPopap.friend = false;
    },
    toggleEditTitle() {
      this.isEditTitle = !this.isEditTitle;
    },
    onUpdateTitle() {
      this.$eventBus.$emit('updateTitle', this.newTitle);
    },
    async deleteProject() {
      try {
        await ProjectService.delete(this.projectID);
        this.$router.push({ name: 'strategyProjectsPage' });
      } catch (e) {
        this.hideModal(this.modalDeleteProject);
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async saveSummary(data) {
      if (data.video || data.video === '') this.textButtonUpdateVideo = 'Loading...';
      else this.textButtonSave = 'Loading...';

      await this.updateProjectData(data);
      this.$eventBus.$emit('onUpdateSummary');
    },
    showSizeAlert() {
      this.showAndHideAlertSuccess("File didn't upload. Max size can be 5Mb.", 'danger');
    },
  },
  watch: {
    isEditTitle(newValue) {
      if (newValue) {
        setTimeout(
          () => { this.$refs.editTitle.focus(); },
          0,
        );
      }
    },
    description() {
      this.isEditTitle = false;
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('updateProjectProperty', this.updateProjectData);
    this.$eventBus.$off('actionUser', this.showModalAction);
    this.$eventBus.$off('avatarClick', this.showUserMenuPopap);
    this.$eventBus.$off('hideMenuPopap', this.showUserMenuPopap);
    this.$eventBus.$off('unfriendUser', this.unfriendUser);
  },
};
</script>
<style lang="scss" scoped>
.btn_delete {
  background-color: #cacaca;
  color: rgb(255, 255, 255);
  margin-right: 10px;

  @media (max-width: 365px) {
    margin-top: 10px
  }
}

.btn_delete:hover {
  background-color: rgb(244, 67, 54)
}

.editor__container {
  ::v-deep .container {
    margin: 0;
  }
}

.dropdown-item {
  ::v-deep &.link-delete {
    color: #7C1D00;
  }
}
</style>
