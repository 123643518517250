<template>
  <div class="editor-member__block">
    <div class="editor-member__title">{{user.name}}</div>
    <div class="editor-member__grid">
      <div class="editor-member__avatar">
        <img v-if="user.avatar" :src="user.avatar" alt="avatar">
        <img v-else src="@/assets/images/avatar.svg" alt="avatar">
      </div>
      <ul class="editor-member__list">
        <li v-if="user.friend">
          <router-link
            :to="{ name: 'MessagesPage', params: { id: user.id } }"
          >
            <i class="editor-member__icon icon-message-ed"></i>
            <span>Message</span>
          </router-link>
        </li>
        <li v-if="!user.friend && user.friend_status !== 'sent'">
          <a href @click.prevent="onActionUser(user.id, 'sent')">
            <i class="editor-member__icon icon-add-ed"></i>
            <span>Add as Friend</span>
          </a>
        </li>
        <li v-if="!user.friend && user.friend_status === 'received'">
          <a href @click.prevent="onActionUser(user.id, 'accept')">
            <i class="editor-member__icon icon-add-ed"></i>
            <span>Accept Friend</span>
          </a>
        </li>
        <li v-if="!user.friend && user.friend_status === 'received'">
          <a href @click.prevent="onActionUser(user.id, 'deny')">
            <i class="editor-member__icon icon-add-ed"></i>
            <span>Deny Friend</span>
          </a>
        </li>
        <li v-if="user.friend && user.friend_status === 'approved'">
          <a href @click.prevent="onActionUser(user.id, 'revoked')">
            <i class="editor-member__icon icon-add-ed"></i>
            <span>Unfriend</span>
          </a>
        </li>
        <li v-if="user.type === 'member' && (authUserType === 'owner' || authUserType === 'admin')"
        >
          <a href @click.prevent="onActionUserProject('moderator')">
            <i class="editor-member__icon icon-promote-ed"></i>
            <span>Promote to Moderator</span>
          </a>
        </li>
        <li v-if="isCanKickOut">
          <a href @click.prevent="onActionUserProject('revoke')">
            <i class="editor-member__icon icon-kick-ed"></i>
            <span>Kick out of Strategy Project</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import FriendService from '@/services/FriendService';

export default {
  name: 'UserMenuPopap',
  components: {},
  mixins: [],
  props: {
    user: {
      type: Object,
      required: true,
    },
    authUserType: {
      type: String,
      default: 'member',
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    isCanKickOut() {
      if (this.authUserType === 'member') return false;
      if (this.authUserType === 'admin' && this.user.type !== 'member') return false;
      if (this.user.type === 'owner') return false;

      return true;
    },
  },
  methods: {
    async onActionUser(userID, action) {
      let res = null;
      try {
        res = await FriendService.actionUser({
          action,
          user_id: userID,
        });

        if (action === 'revoked') this.$eventBus.$emit('unfriendUser', this.user.id);
        else if (action === 'sent') this.user.friend_status = 'sent';

        this.$eventBus.$emit('actionUser', res.data.message);
      } catch (e) {
        this.$eventBus.$emit('actionUser', e.response.data.message, 'danger');
      } finally {
        this.$eventBus.$emit('hideMenuPopap', this.user);
      }
    },
    async onActionUserProject(action) {
      this.$eventBus.$emit('actionUser', this.user, this.user.type, action);
      this.$eventBus.$emit('hideMenuPopap', this.user);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
