<template>
  <div class="editor-summary">
    <div class="editor-summary__wrapper">
      <div class="editor-summary__header">
        <h5 class="editor-summary__title">Media Objects</h5>
        <div class="editor-summary__subtitle">
          Here you can upload various files to be displayed in a media carousel.
          You are able to upload PDFs, images, or gifs.
        </div>
      </div>
      <div class="file-drop__wrapper">
        <h3 class="file-drop__title">File caption</h3>
        <input
          v-model="media.text"
          placeholder="File caption"
          class="form-control"
          type="text"
          @keyup.enter="addFile"
        >
        <div class="file-drop">
          <div class="file-drop__body">
            <input
              type="file"
              accept="application/pdf, image/*"
              @change="changeFile"
            >
            <img class="file-drop__icon" src="images/upload.png" alt="">
            <div class="file-drop__info">
              Formats: pdf, jpg, png, gif, bmp
            </div>
            <div v-if="fileName" class="file-drop__info">
              Uploaded file:<br>
              {{fileName}}
            </div>
          </div>
        </div>
        <Alert :variant="variant" v-if="text">{{ text }}</Alert>
        <div class="btns-group--center btn_upload_file">
          <a
          class="btn btn-primary"
          @click="addFile"
          :disabled="!media.file"
          >Upload a file</a>
        </div>
      </div>
      <div v-if="medias.length" class="file__wrapper">
        <h3 class="file__title">List of file:</h3>
        <FileCard
          v-for="(media, index) in medias"
          :key="media.id+media.filename"
          :media="media"
          :sortIndex="index + 1"
          @update="updateMedia"
          @sort="updateMediaPosition"
          @delete="showDeleteMediaModal"
        />
      </div>
    </div>
    <Modal
      @close="hideModal(modalDelete)"
      :id="modalDelete"
      title="Are you sure?"
      buttonTextOk="Delete"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalDelete)"
      @ok="deleteMedia"
    />
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue';
import FileCard from '@/views/pages/StrategyProjectEditor/Components/EditorSection/FileCard.vue';
import Modal from '@/components/Modal.vue';

import badge from '@/mixins/badge';
import alert from '@/mixins/alert';
import modal from '@/mixins/modal';

import ProjectService from '@/services/ProjectService';
import MediaService from '@/services/MediaService';

export default {
  name: 'EditorMediaBlock',
  components: { FileCard, Alert, Modal },
  mixins: [badge, alert, modal],
  props: {
    projectID: {
      type: Number,
    },
  },
  data() {
    return {
      media: {
        text: '',
        file: '',
      },
      fileName: '',
      textUpload: 'Upload a file',
      textButtonUpload: 'Upload a file',
      medias: [],
      modalDelete: 'deleteModal',
      deleteMediaID: undefined,
      elementFile: null,
    };
  },
  async created() {
    await this.getMedias();
  },
  computed: {},
  methods: {
    async getMedias() {
      const res = await ProjectService.getMedia(this.projectID, { 'per-page': 50 });
      this.medias = res.data.sort((a, b) => +a.sort - +b.sort);
    },
    changeFile(event) {
      const file = event.target.files[0];

      if (file.type !== 'application/pdf' && !file.type.includes('image/')) {
        return;
      }

      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        this.fileName = event.target.files[0].name;
        this.elementFile = event.target;

        if (event.target.files[0].size >= (1048576 * 5)) {
          this.$emit('onShowSizeAlert');
          return;
        }

        this.media.file = reader.result;
      };
    },
    async addFile() {
      if (!this.media.text) { this.showAndHideAlertSuccess('You can not upload the file without a caption', 'danger'); return; }
      if (!this.media.file) { this.showAndHideAlertSuccess('File is empty', 'danger'); return; }

      let res = null;
      this.textButtonUpload = 'Loading...';

      try {
        res = await ProjectService.addMedia(this.projectID, {
          title: this.media.text,
          filename: this.media.file,
        });

        this.medias.push(res.data);
        this.media.text = '';
        this.media.file = '';
        this.fileName = '';
        this.elementFile.files = [];
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
      } finally {
        console.log(res.data);
        this.textButtonUpload = this.textUpload;
      }
    },
    async deleteMedia() {
      try {
        await MediaService.delete(this.deleteMediaID);

        this.medias = this.medias
          .filter((media) => media.id !== this.deleteMediaID);
        this.medias.forEach((media, index) => {
          /* eslint-disable no-param-reassign */
          media.sort = index + 1;
        });
        this.hideModal(this.modalDelete);
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
      }
    },
    async updateMedia(id, data) {
      try {
        await MediaService.update(id, data);
        const mediaCopy = this.medias.filter((media) => media.id === id);
        mediaCopy.title = data.title;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
      }
    },
    async updateMediaPosition(id, direction) {
      if (+this.media.sort === 1 && direction === 'up') return;
      if (+this.media.sort === this.media.length && direction === 'down') return;

      try {
        await MediaService.sort(id, direction);

        this.getMedias();
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
      }
    },
    showDeleteMediaModal(id) {
      this.deleteMediaID = id;
      this.showModal(this.modalDelete);
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.editor-summary {
  margin-bottom: 50px;

  // &__wrapper {
  //   text-align: center;
  // }

  &__editor {
    margin-top: 40px;
  }
}

// .file-drop {
//   margin-bottom: 0;
// }

.remove_file {
  display: block;
  color: red;
  font-size: 12px;

  cursor: pointer;
}

.uploaded_file {
  color: blue;
  font-size: 12px;

  cursor: pointer;
}

.user-cabinet__area-top {
  margin-bottom: 20px;
  margin-top: 30px;
}

.btn_upload_file a[disabled] {
  background-color: #eee;
  cursor: default;
}

.btn_upload_file a[disabled]:hover {
  color: #000;
}

.error {
  text-align: left;
  /* position: relative; */
  /* left: 0; */
  display: inline-block;
  width: 100%;
  padding-left: 20px;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute !important;
  top: 50%;
  right: 10px;

  cursor: pointer;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  transform: translateY(-50%);

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
