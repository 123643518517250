<template>
  <div class="container_found_users">
    <div class="add-member__avatar">
      <div
        v-if="user.avatar"
        class="avatar"
        :style="'background-image: url(' + user.avatar + ')'">
      </div>
      <div
        v-else
        class="avatar"
        :style="'background-image: url(' +
          require('@/assets/images/avatar.svg') +
          ')'"
      >
      </div>
    </div>
    <div class="add-member__name">{{user.name}}</div>
    <a class="add-member__action" href="#" @click.prevent="onAddUser">Add</a>
  </div>
</template>
<script>
import UserService from '@/services/UserService';

import exception from '@/mixins/exception';

export default {
  name: 'FoundUsers',
  components: {},
  mixins: [exception],
  props: {
    user: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'request',
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onAddUser() {
      if (this.type === 'admin') this.addUserHowAdmin();
      else if (this.type === 'request') this.addUserHowMember();
    },
    async addUserHowAdmin() {
      this.$eventBus.$emit('actionUser', this.user, this.user.type, 'moderator');
    },
    async addUserHowMember() {
      let res = null;

      try {
        res = await UserService.invite({
          user_id: this.user.id,
          project_id: +this.$route.params.id,
        });
        this.$eventBus.$emit('actionUser', res.data.message);
        this.$eventBus.$emit('clearSearchText');
      } catch (e) {
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
