<template>
  <div class="card-member__item">
    <span v-if="user.isUnreadMember && authUserType === 'owner'"
      class="message_badge"
      :class="getClassBadgeLength(user.isUnreadMember)"
    >{{user.isUnreadMember}}
    </span>
    <div class="card-member__avatar">
      <div
        v-if="user.avatar"
        class="avatar"
        :style="'background-image: url(' + user.avatar + ')'">
      </div>
      <div
        v-else
        class="avatar"
        :style="'background-image: url(' +
          require('@/assets/images/avatar.svg') +
          ')'"
      >
      </div>
    </div>
    <div class="card-member__name">{{user.name}}</div>
    <a
      v-if="user.type === 'admin' && authUserType === 'owner'"
      class="btn-kick"
      href=""
      @click.prevent="onActionUser('member')"
    >
      Kick out
    </a>
    <div
      v-if="user.type === 'request' && (authUserType === 'owner' || authUserType === 'admin')"
      class="card-member__action"
    >
      <a
        class="btn-kick active"
        href=""
        @click.prevent="onActionUser('accept')"
      >
        Accept
      </a>
      <a
        class="btn-kick"
        href=""
        @click.prevent="onActionUser('deny')"
      >
        Deny
      </a>
    </div>
  </div>
</template>
<script>
import badge from '@/mixins/badge';

export default {
  name: 'CardUser',
  components: {},
  mixins: [badge],
  props: {
    user: {
      type: Object,
      required: true,
    },
    authUserType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    async onActionUser(action) {
      this.$eventBus.$emit('actionUser', this.user, this.user.type, action);
    },
  },
};
</script>

<style lang="scss" scoped>
.message_badge {
  top: -5px;
  right: -5px;
}
</style>
