<template>
  <div>
    <h3>{{title}}</h3>
    <div v-if="users.length" class="card-member__list">
      <CardUser
        v-for="user in users"
        :key="user.id + user.name"
        :user="user"
        :authUserType="authUserType"
      />
    </div>
    <div v-if="canAddUser" class="add-member">
      <div class="add-member__title">{{titleAdd}}</div>
      <div class="add-member__box">
        <a class="add-member__remove" href="#"><span>-</span></a>
        <div>
          <transition-group name="component-fade" mode="out-in">
            <FoundUsers
              v-for="user in foundUsers"
              :key="user.id + user.name"
              :user="user"
              :type="type"
            />
          </transition-group>
        </div>
      </div>
      <input
        v-model="searchText"
        @input="onSearchUser"
        type="text"
        placeholder="Search..."
      >
    </div>
  </div>
</template>
<script>
import CardUser from '@/views/pages/StrategyProjectEditor/Components/LeftSideMenu/CardUser.vue';
import FoundUsers from '@/views/pages/StrategyProjectEditor/Components/LeftSideMenu/FoundUsers.vue';

import ProjectService from '@/services/ProjectService';
import FriendService from '@/services/FriendService';

import exception from '@/mixins/exception';

export default {
  name: 'ListOFUsers',
  components: { CardUser, FoundUsers },
  mixins: [exception],
  props: {
    users: {
      type: Array,
    },
    authUserType: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'owner',
    },
  },
  data() {
    return {
      searchText: '',
      foundUsers: [],
    };
  },
  created() {
    this.$eventBus.$on('clearSearchText', this.clearSearchText);
  },
  computed: {
    title() {
      if (this.type === 'owner') {
        return 'Initiator(s)';
      }

      if (this.type === 'admin') {
        return 'Moderators';
      }

      return 'New Members';
    },
    titleAdd() {
      if (this.type === 'owner') {
        return 'Add Initiators';
      }

      if (this.type === 'admin') {
        return 'Add Moderators';
      }

      return 'Add Members';
    },
    canAddUser() {
      if (this.type === 'owner') return false;
      if (this.type === 'admin' && this.authUserType !== 'owner') return false;
      if (this.type === 'request' && this.authUserType === 'member') return false;

      return true;
    },
  },
  methods: {
    async onSearchUser() {
      if (this.searchText.length < 3) {
        this.foundUsers = [];
        return;
      }

      if (this.type === 'admin') this.searchUser();
      else if (this.type === 'request') this.searchFriend();
    },
    async searchUser() {
      let res = null;
      try {
        res = await ProjectService.getUsersProject(this.$route.params.id, {
          filter: 'member',
          name: this.searchText,
          page: 1,
          'per-page': 5,
        });

        this.foundUsers = res.data;
      } catch (e) {
        this.foundUsers = [];
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
        this.handleException(e);
      }
    },
    async searchFriend() {
      let res = null;
      const data = {
        filter: 'all',
        name: this.searchText,
        'per-page': 5,
      };

      try {
        res = await FriendService.get(data);
        this.foundUsers = res.data.filter((friend) => friend.status === 'approved');
      } catch (e) {
        this.friends = [];
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
        this.handleException(e);
      }
    },
    clearSearchText() {
      this.searchText = '';
      this.foundUsers = [];
    },
  },
};
</script>
<style lang="scss" scoped>
  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
  }
  .component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
</style>
