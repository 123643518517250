<template>
  <div class="card-body">
    <ul class="category-list">
      <li v-for="category in categories" :key="category.id + category.name">
        <label>
          <input
            v-model="selectedCategory"
            class="radio-box"
            type="radio"
            name="category"
            :value="category.id"
            :disabled="authUserType !== 'owner'"
            @change="onChangeCategory"
          >
          <span class="radio-style"></span>{{category.name}}
        </label>
      </li>
    </ul>
  </div>
</template>
<script>
import categories from '@/mixins/categories/getCategories';

export default {
  name: 'ListCategories',
  mixins: [categories],
  props: {
    curCategory: {
      type: String,
      default: '',
    },
    authUserType: {
      type: String,
    },
  },
  data() {
    return {
      selectedCategory: 0,
    };
  },
  methods: {
    onChangeCategory() {
      this.$eventBus.$emit('updateProjectProperty', { category_id: this.selectedCategory });
    },
  },
  watch: {
    categories(val) {
      if (!val) return;

      this.selectedCategory = this
        .categories
        .find((category) => category.name === this.curCategory).id;
    },
  },
};
</script>
